import React from 'react'

const Revenue = () => {
    return (
        <div className='flex justify-center items-center bg-[#E8F1FD] max-md:mt-5 '>
            <div className="flex flex-col justify-center items-center max-w-[1280px] py-10 px-10 max-md:px-5 max-md:py-5">
                <div className="self-center text-[40px] max-md:text-[28px] font-semibold lg:leading-12 leading-[36px]  text-[#0A3A75]">
                    <span className="font-medium">Increase </span>
                    <span className="font-bold text-[#FD5C01]">revenue </span>
                    <span className="font-medium">from your hotel website</span>
                </div>
                <div className='flex justify-center items-center mt-5'>
                    <div className="grid grid-cols-3 gap-5  max-md:gap-5 max-md:grid-cols-1 mt-7 w-full text-[#333333]">
                        <div className="flex gap-4   justify-between" >
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6ec2c6685c94071abf5a2676417083cb38a7bb9982814163e8820fca19015e1c?"
                                className="self-start lg:w-16 w-8 aspect-square"
                            />
                            <div className="flex flex-col flex-1">
                                <div className="text-xl font-semibold leading-6">
                                    More languages & currencies
                                </div>
                                <div className="mt-2 text-md text-[#333333] leading-6">
                                    Take booking from guests and over the world in their currency of
                                    choice
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4   justify-between ">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a564ae26a34fa14626049dbfdbad16eb184660ce9219ab601f4a234791591394?"
                                className="self-start lg:w-16 w-8 aspect-square"
                            />
                            <div className="flex flex-col flex-1">
                                <div className=" text-xl font-semibold leading-6">
                                    Add extras & upsell offers
                                </div>
                                <div className=" mt-2 text-md text-[#333333] leading-6">
                                    Let guests add extras (like breakfast), and upsell your offers
                                    directly on your website.
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4   justify-between ">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f5975bfd34bfb136f24cd2a53ff33d23df2d48efb5fb265febed23c80c7cb98b?"
                                className="self-start lg:w-16 w-8 aspect-square"
                            />
                            <div className="flex flex-col flex-1">
                                <div className="text-xl font-semibold leading-6 ">
                                    Offers, promos & discounts
                                </div>
                                <div className="mt-2 text-md text-[#333333] leading-6">
                                    Boost your bookings with special offers, seasonal promos and
                                    return-stay discounts.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Revenue