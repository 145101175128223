import React, { useState } from 'react'
import Slider from "react-slick";
import GDE from "../assets/4172logo.png"
import TheJAmunTree from "../assets/download (1).jpg"
import Les from "../assets/logo-transparent-png.png"
import Ebc from "../assets/ebkdjsjfksk.jpg"
import Ananda from "../assets/andandan.png"
import { Link } from 'react-router-dom';
const ClientCard = ({ partnerData }) => {

    const [index, setIndex] = useState(0)
    const data = [
        "https://eazotel-client-images.s3.ap-south-1.amazonaws.com/sparvhospitality/images/c289d689-3c35-40b1-a0e9-687841f8f398.png",
        "https://eazotel-client-images.s3.ap-south-1.amazonaws.com/bhairahawagardenresort/images/20bae61c-09ec-4822-a499-3cdd88b615f4.png",
        "https://eazotel-client-images.s3.ap-south-1.amazonaws.com/peaceatpeak/images/0512b272-1f53-4685-a5e4-3d072deec9f3.jpg",
        Ebc,
        "https://eazotel-client-images.s3.ap-south-1.amazonaws.com/hotelawadhvilasayodhya/images/1c5ceb2d-fe58-4343-9ba4-d145b6479143.jpg",
        Ananda,
        GDE,
        TheJAmunTree,
        Les,

    ]


    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        initialSlide: 0,
        afterChange: (index) => setIndex(index),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            }
        ]
    };


    return (
        <div className="client flex justify-center py-10 mt-10 max-md:px-5">
            <div className='max-w-[1280px] max-md:w-full flex flex-col items-center'>
                <div className="text-4xl font-bold text-neutral-600">
                    Our Clients
                </div>
                <div className=" w-full flex gap-5 justify-center self-stretch mt-8 max-md:px-0  ">
                    <Slider {...settings} className='flex justify-center items-center  w-full max-md:w-full max-md:ml-0 '>
                        {data.map((image, index) => (
                            <div key={index} className='pl-2 pr-2 flex justify-center items-center'>
                                <img
                                    src={image}
                                    className="w-[130px] h-[130px] object-contain"
                                    alt="client"
                                    loading="lazy"
                                />
                            </div>
                        ))}
                    </Slider>

                </div>

                <div className="flex justify-center items-center gap-1 px-5 mt-6">
                    {[...Array(data.length)].map((_, i) => (
                        <div
                            key={i}
                            className={` shrink-0 h-2 rounded-lg ${index === i ? 'bg-[#0A3A75] w-[15px]' : 'w-[8px] bg-stone-300'}`}
                        />
                    ))}
                </div>

                <div className="flex max-md:justify-center mt-10 max-md:mt-5">
                    <Link to="https://app.eazotel.com/our-work" target='_blank' className="px-14 max-md:px-5 py-3.5 text-base text-white bg-[#FD5C01] rounded-lg whitespace-nowrap">
                        View More
                    </Link>
                </div>
            </div>


        </div>
    )
}

export default ClientCard