import React, { useState } from 'react'
import Dinabite from "../assets/Dinabite 1.svg"
import Razorpay from "../assets/razor.svg"
import Autiller from "../assets/Aut.svg"
import Stripe from "../assets/str.svg"
import Fielmente from "../assets/fielmente.svg"
import PhonePe from "../assets/phonepay.jpg"
import Slider from 'react-slick'

const Partner = () => {

    const [index, setIndex] = useState(0)
    const data = [
        Dinabite,
        Razorpay,
        Stripe,
        Fielmente,
        Autiller,
        PhonePe,
    ]

    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        initialSlide: 0,
        afterChange: (index) => setIndex(index),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            }
        ]
    };


    return (
        <div className="partner flex flex-col max-md:px-5">
            <div className="w-full text-4xl leading-10 text-center max-md:max-w-full max-md:text-start">
                <span className="font-medium max-md:text-[34px] text-[#0A3A75]">Our </span>
                <span className="font-bold max-md:text-[34px]  text-[#FD5C01]">Incredible Partners </span>
                <span className=" max-md:text-[34px] font-medium text-[#0A3A75]">who make it easy</span>
            </div>
            <div class="parent max-md:px-0 flex gap-16 justify-between self-center mt-12 max-w-[1084px] max-md:w-full px-24 max-md:py-0">
                <Slider {...settings} className='flex justify-center items-center  w-full max-md:w-full max-md:ml-0 '>
                    {data.map((image, index) => (
                        <div key={index} className='pl-2 pr-2 flex justify-center items-center'>
                            <img
                                src={image}
                                className="w-[130px] h-[130px] object-contain"
                                alt="partner"
                                loading="lazy"
                            />
                        </div>
                    ))}
                </Slider>

            </div>
        </div>
    )
}

export default Partner