import React from 'react'
import logo from "../assets/EAZOTEL.svg"
const Navbar = ({ scrollToForm }) => {
    return (

        <div className='flex justify-center'>
            <div className="flex justify-between max-w-[1280px] text-xs font-semibold text-white whitespace-nowrap bg-white shadow-sm w-full max-md:max-w-full max-md:justify-between max-md:pl-3 max-md:pr-5 max-md:py-2 ">
                <img
                    loading="lazy"
                    src={logo}
                    className="max-w-full aspect-[2.86] w-[227px] max-md:w-[154px]"
                    alt='eazotel'
                />
                <button onClick={scrollToForm} className="text-white justify-center text-[14px] max-md:w-auto px-3 py-3 my-auto bg-[#FD5C01] rounded-lg max-md:py-2.5 max-md:px-3 max-md:text-[14px]" >
                    Request Call Back
                </button>
            </div>
        </div>
    )
}

export default Navbar