import React, { useRef } from "react";
import Banner from "../components/Banner";
import Hospitality from "../components/Hospitality";
import ClientCard from "../components/ClientCard";
import ReachCard from "../components/ReachCard";
import Feature from "../components/Feature";
import Partner from "../components/Partner";
import ImageCard from "../components/ImageCard";
import Testimonials from "../components/testimonials";
import Footer from "../components/Footer";
import Revenue from "../components/Revenue";
import { Link } from "react-router-dom";
import Call from "../assets/pho.webp";
import WhatsApp from "../assets/2062095_application_chat_communication_logo_whatsapp_icon.svg.png";
import Navbar from "../components/Navbar";
import Cookies from "../components/Cookies";
const Home = () => {
  const formRef = useRef(null); // Ref for the Form component

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="container mx-auto relative overflow-hidden">
      <Navbar scrollToForm={scrollToForm} />
      <Banner scrollToForm={scrollToForm} />
      <Hospitality />
      <ClientCard />

      <Revenue />

      {/* <ReachCard /> */}

      <Feature scrollToForm={scrollToForm} />

      <Partner />

      <ImageCard ref={formRef} />

      <Testimonials />

      <Footer scrollToForm={scrollToForm} />

      {/* <Cookies /> */}

      <div className='fixed bottom-[30px] left-[20px]'>
        <Link to="tel:+919501868775">
          <img src={Call} alt="call" className='h-[4rem] max-md:h-[3rem] w-100' />
        </Link>
      </div>
      <div className='fixed bottom-[90px] right-[20px]'>
        <Link to="https://wa.me/9501868775?text=<message>" target='_blank'>
          <img src={WhatsApp} alt="call" className='h-[4rem] max-md:h-[3rem] w-100' />
        </Link>
      </div>
    </div>
  );
};

export default Home;
