import React, { useState } from "react";
import Cookie from "../assets/cookie.svg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const CookiesPage = () => {
  const [acceptedCookies, setAcceptCookies] = useState();

  const acceptCookies = () => {
    Cookies.set("cookiesAccepted", "true", { expires: 1 });
    setAcceptCookies(true);
  };

  if (acceptedCookies) return null;

  return (
    <div className=" bg-[#E8F1FD] text-[#0A3A75]">
      <div className="flex gap-5 items-center max-w-[1280px] mx-auto p-6 text-base max-md:flex-wrap max-md:px-5">
        <img
          loading="lazy"
          src={Cookie}
          className="shrink-0 self-stretch my-auto w-10 aspect-square"
        />
        <div className="flex-1 self-stretch leading-5   max-md:max-w-full">
          <span className="font-bold ">
            We use cookies in the delivery of our services.
          </span>
          <span className="">
            To learn about the cookies we use and information about your
            preferences and opt-out choices,{" "}
          </span>
          <Link className="underline ">please click here</Link>
          <span className="">
            . By using our platform you agree to our use of cookies.
          </span>
        </div>
        <div className="flex gap-4 self-stretch pl-9 my-auto font-bold whitespace-nowrap">
          <button className="my-auto ">Decline</button>
          <button
            onClick={acceptCookies}
            className="justify-center px-5 py-2 text-white bg-[#FD5C01]"
          >
            Allow
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesPage;
