import React, { useState } from 'react';
import Slider from "react-slick";
import "../style/Client.css";
import "../style/testimonial.css";
import TestimonialCard from './testimonialCard';


const Testimonials = () => {

    const [index, setIndex] = useState()
    var settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        afterChange: (index) => setIndex(index),
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="testimonial flex flex-col my-0 px-5 max-md:px-5">
            <div className="text-4xl font-semibold leading-10 text-center max-md:text-start text-[#FD5C01] max-md:max-w-full">
                <span className="font-medium text-[#0A3A75]">Less from Us,</span>
                <br />
                <span className="font-bold text-[#FD5C01]">
                    More from our happy customers
                </span>
            </div>
            <div className=" w-full flex gap-10 justify-between self-stretch mt-10 px-24 max-md:px-0  ">

                <Slider {...settings} className='gap-10 max-md:px-5 flex w-full max-md:ml-0 max-md:w-full '>
                    <div className='px-4 max-md:px-2'>
                        <TestimonialCard
                            name="Divyanshu Sharma"
                            data="Eazotel made building our hotel website a breeze. The intuitive interface and customizable templates allowed us to showcase our property uniquely. It's a game-changer for establishing a strong online presence."

                        />
                    </div>
                    <div className='px-4 max-md:px-2'>
                        <TestimonialCard
                            name="Anant Agarwal"
                            data="Eazotel's customer support is exceptional. The team is responsive, knowledgeable, and always ready to assist. Their dedication ensures that any challenges we face are quickly resolved, making our experience with the platform smooth and worry-free."
                        />
                    </div>
                    <div className='px-4 max-md:px-2'>
                        <TestimonialCard
                            name="Jatin Mishra"
                            data="Eazotel is a one-stop platform from where we can update our website, control inventory across multiple channels and process reservations and payments for our guests"
                        />
                    </div>
                    <div className='px- max-md:px-2'>
                        <TestimonialCard
                            name="Saloni Koolwal"
                            data="Eazotel's extensive range of templates saved us valuable time in the website development process. We found the perfect match for our boutique hotel, and the ease of customization allowed us to tailor it to our brand seamlessly."
                        />
                    </div>
                </Slider>

            </div>
            <div className="flex gap-1 self-center mt-7 w-[49px]">
                {[...Array(settings.slidesToShow)].map((_, i) => (
                    <div
                        key={i}
                        className={` shrink-0 rounded-lg ${index === i ? 'bg-[#FD5C01] w-[15px] h-2' : 'w-[8px] bg-[#FFCEB3]'}`}
                    />
                ))}
            </div>



        </div>
    )
}

export default Testimonials