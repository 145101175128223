import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Home from "./screen/Home.jsx"

function App() {
  return (
    <div>

      <Routes>
        <Route index element={<Home />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
