import React, { useState } from 'react';
import "../style/testimonial.css";
const TestimonialCard = ({ name,data }) => {

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const descriptionClass = showFullDescription ? '' : 'truncate';
    return (
        <div className="testimonial flex flex-col grow justify-center px-6 py-10 w-full text-xs size-max rounded-3xl border border-solid bg-[#f2f2f2] border-[#BDBDBD] text-[##333333] max-md:px-5 max-md:mt-8">
            {/* <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/73ccea072365a71ef2d0e2e0651c11b389bdbdce96324ec05ff7f0beb5eb7665?apiKey=c81aa461ed88497c9715fa37b92c8996&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/73ccea072365a71ef2d0e2e0651c11b389bdbdce96324ec05ff7f0beb5eb7665?apiKey=c81aa461ed88497c9715fa37b92c8996&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/73ccea072365a71ef2d0e2e0651c11b389bdbdce96324ec05ff7f0beb5eb7665?apiKey=c81aa461ed88497c9715fa37b92c8996&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/73ccea072365a71ef2d0e2e0651c11b389bdbdce96324ec05ff7f0beb5eb7665?apiKey=c81aa461ed88497c9715fa37b92c8996&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/73ccea072365a71ef2d0e2e0651c11b389bdbdce96324ec05ff7f0beb5eb7665?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/73ccea072365a71ef2d0e2e0651c11b389bdbdce96324ec05ff7f0beb5eb7665?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/73ccea072365a71ef2d0e2e0651c11b389bdbdce96324ec05ff7f0beb5eb7665?apiKey=c81aa461ed88497c9715fa37b92c8996&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/73ccea072365a71ef2d0e2e0651c11b389bdbdce96324ec05ff7f0beb5eb7665?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                className="self-center aspect-square w-[91px]"
            /> */}
            <div className="self-center mt-2 text-base text-[##333333] font-semibold leading-6 whitespace-nowrap">
               {name}
            </div>
            {/* <div className="text-center text-[#4F4F4F] whitespace-nowrap leading-[133%] text-neutral-600">
                CEO & Founder of Kapoor Hotels
            </div> */}
            <div className="mt-5 text-center text-[#333333] leading-[18px] text-[16px] " style={{ overflow: "hidden", flexWrap: "wrap" }}>
                <p>{showFullDescription ? (
                    <>
                        {data}<span className=" cursor-pointer" onClick={toggleDescription}>...less</span>
                    </>
                ) : (
                    <>
                        {data.slice(0, 120)}
                        <span className=" cursor-pointer" onClick={toggleDescription}>...more</span>
                    </>
                )}</p>
            </div>
        </div>
    )
}

export default TestimonialCard